<template>
  <div
    class="bg-grey relative"
    :class="blok.has_padding ? 'add-padding' : 'no-padding'"
  >
    <div class="container about-us-cards flex p-20 gap-x-8">
      <div v-for="blok in blok.blocks" :key="blok.index" class="flex-1">
        <AboutUsCardText :blok="blok" />
      </div>
    </div>
    <div v-if="blok.has_padding" class="curve-line"></div>
  </div>
</template>
<script>
export default {
  name: "AboutUsCards",
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
};
</script>
<style scoped lang="scss">
.bg-grey {
  background-color: #efefef;
  overflow: hidden;
  padding-top: 2px;
}
.container {
  @media only screen and (max-width: 767px) {
    @apply px-10;
  }
}
.about-us-cards {
  @apply pb-10;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
}
.add-padding {
  @apply pt-40;
  @media only screen and (max-width: 767px) {
    @apply pb-10 pt-20;
  }
}
.no-padding {
  @apply pt-0;
}
.curve-line {
  position: absolute;
  height: 260px;
  width: 120%;
  margin: auto;
  top: -20%;
  margin-left: -10vw;
  margin-right: -10vw;
  background-color: white;
  clip-path: ellipse(50% 50% at 50% 50%);
  overflow: hidden;
  @media only screen and (max-width: 769px) {
    height: 100px;
    top: -10%;
    clip-path: ellipse(50% 50% at 50% 50%);
  }
  @media only screen and (max-width: 481px) {
    height: 155px;
    clip-path: ellipse(50% 50% at 50% 50%);
  }
}
</style>
