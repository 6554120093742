<template>
  <div class="mt-16 ourMission">
    <div class="text-center max-w-lg m-auto">
      <div
        v-html="$storyapi.richTextResolver.render(blok.title)"
        class="text-red-title text-3xl mb-6 font-mont"
      />
      <div
        v-html="$storyapi.richTextResolver.render(blok.text)"
        class="mb-6 text-lg font-fgb"
      />
    </div>
    <div class="images-wrapper">
      <div class="images flex relative">
        <div class="curve-line"></div>
        <nuxt-img
          quality="90"
          format="webp"
          placeholder
          :src="blok.image_1.filename + '/m/filters:format(webp)'"
          class="image-1"
          alt="curved line"
          width="625"
          height="418"
        />
        <nuxt-img
          quality="90"
          format="webp"
          placeholder
          :src="blok.image_2.filename + '/m/filters:format(webp)'"
          class="image-2"
          alt="curved line"
          width="625"
          height="418"
        />
        <nuxt-img
          quality="90"
          format="webp"
          placeholder
          :src="blok.image_3.filename + '/m/filters:format(webp)'"
          class="image-3"
          alt="curved line"
          width="625"
          height="418"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AboutUsOurMission",
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  @apply md:mt-20 mt-8;
}
.ourMission {
  @media only screen and (max-width: 767px) {
    .text-center {
      @apply px-10;
    }
  }
}
.images-wrapper {
  overflow: hidden;
  img {
    width: 100px;
    height: auto;
  }
  .curve-line {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 120%;
    margin: auto;
    top: -73%;
    margin-left: -10%;
    background-color: white;
    border-radius: 2000px 866px 2000px 2000px / 0px 0px 433px 433px;
  }
  .images {
    padding-top: 2px;
    gap: 15px;
    .image-1,
    .image-2,
    .image-3 {
      @apply basis-1/3;
    }
    @media only screen and (max-width: 768px) {
      .image-1,
      .image-2,
      .image-3 {
        @apply basis-1/2;
      }
      .image-2 {
        @apply hidden;
      }
    }
  }
}
</style>
