<template>
  <section
    class="basic-hero bg-no-repeat bg-cover"
    :style="{
      background:
        'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' +
        blok.background_image.filename + '/m/fit-in/1905x530/' +
        ')',
    }"
  >
    <div
      class="text"
      v-html="$storyapi.richTextResolver.render(blok.text)"
    ></div>
  </section>
</template>

<script>
export default {
  name: "BasicHero",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-hero {
  @apply flex justify-center items-center;
  width: 100%;
  height: calc(80vh - 243px);
  max-height: 530px;
  background: #3c3c3c;
  background-position: center !important;
  background-repeat: no-repeat;
  background-size: cover;
  ::v-deep .text {
    text-align: center;
    font: normal normal normal 55px/80px "Adobe Caslon Pro", serif;
    color: white;
    b {
      font: normal normal bold 95px/100px "Adobe Caslon Pro", serif;
    }
    p:last-child {
      font: normal normal normal 45px/100px "Adobe Caslon Pro", serif;
    }
  }

  @media only screen and (max-width: 1024px) {
    ::v-deep .text {
      text-align: center;
      font: normal normal normal 45px/55px "Adobe Caslon Pro", serif;
      color: white;
      b {
        font: normal normal bold 70px/75px "Adobe Caslon Pro", serif;
      }
      p:last-child {
        font: normal normal normal 35px/55px "Adobe Caslon Pro", serif;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    height: calc(80vh - 30vh);
    ::v-deep .text {
      text-align: center;
      font: normal normal normal 44px/55px "Adobe Caslon Pro", serif;
      color: white;
      b {
        font: normal normal bold 55px/55px "Adobe Caslon Pro", serif;
      }
      p:last-child {
        font: normal normal normal 35px/55px "Adobe Caslon Pro", serif;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    height: calc(100vh - 60vh);
    ::v-deep .text {
      @apply px-2;
      text-align: center;
      font: normal normal normal 33px/44px "Adobe Caslon Pro", serif;
      color: white;
      b {
        font: normal normal bold 33px/44px "Adobe Caslon Pro", serif;
      }
      p:last-child {
        font: normal normal normal 28px/44px "Adobe Caslon Pro", serif;
      }
    }
  }
}
</style>
