<template>
  <div class="lg:flex container aboutUsCeo">
    <div class="ceo-img-wrapper">
      <nuxt-img
        quality="90"
        format="webp"
        :src="blok.image.filename + '/m/filters:format(webp)'"
        class="ceo-img rounded-xl"
        alt="Our CEO"
        width="760"
        height="500"
      />
    </div>
    <div class="ceo-text xl:px-32 xl:py-20 md:px-2 md:py-2">
      <div class="relative">
        <div
          v-html="$storyapi.richTextResolver.render(blok.Title)"
          class="text-red-title text-4xl md:mb-8 mb-4 font-mont mt-8 lg:mt-0"
        />
        <div
          v-html="$storyapi.richTextResolver.render(blok.text)"
          class="font-fgb richtext-paragraph text-lg"
        />
        <nuxt-img
          quality="90"
          format="webp"
          :src="blok.signature.filename + '/m/filters:format(webp)'"
          class="signature"
          alt="signature"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutUsCeo",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  @apply md:mt-24 mb-16 mt-6 py-12;
  @include for-mobile {
    @apply px-10 my-0 pt-0;
  }
  &.aboutUsCeo {
    @include for-mobile {
      @apply flex flex-col;
      .ceo-text {
        @apply order-1;
      }
      .ceo-img-wrapper {
        @apply order-2;
      }
    }
  }
}
.ceo-img-wrapper {
  flex-basis: 50%;
  padding-right: 2%;
  @media only screen and (max-width: 1023px) {
    padding-right: 0;
  }
}
.ceo-text {
  flex-basis: 50%;
}
.ceo-img {
  // border-top-right-radius: 25px;
  // border-bottom-right-radius: 25px;
  max-width: 100%;
  height: auto;
  @media only screen and (max-width: 1023px) {
    @apply px-28;
  }
  @media only screen and (max-width: 480px) {
    padding: 0;
  }
}
.signature {
  margin: 25px 25px 0 auto;
  width: 90px;
  @media only screen and (max-width: 1023px) {
    margin: 25px auto 25px 0;
  }
}
.text-paragraph {
  p {
    margin-top: 30px;
  }
}
</style>
