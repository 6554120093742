var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.isMobile &&
    _vm.blok.about_us_hero_mobile_img &&
    _vm.blok.about_us_hero_mobile_img.filename
  )?_c('div',{staticClass:"about-us-hero-banner-mobile mobile-only"},[_c('div',{staticClass:"about-us-hero-image-mobile"},[_c('div',[_c('section',{staticClass:"about-hero-mobile"},[_c('img',{attrs:{"src":_vm.blok.about_us_hero_mobile_img.filename + '/m/filters:format(webp)',"alt":"blok.about_us_hero_mobile_img.alt"}})])])])]):_c('div',[(_vm.blok.AboutUsHeroVideo && _vm.blok.AboutUsHeroVideo.filename)?_c('div',{staticClass:"about-us-hero-banner desktop-only",style:({ 'background-color': _vm.blok.AboutUsHeroBackgroundColor })},[_c('div',{staticClass:"about-wrapper"},[_c('video',{staticClass:"m-auto",attrs:{"autoplay":"","loop":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.blok.AboutUsHeroVideo.filename,"type":"video/mp4"}})])]),_vm._v(" "),_c('div',{staticClass:"about-text"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.$storyapi.richTextResolver.render(_vm.blok.AboutUsHeroText))}})])]):(
      _vm.blok.AboutUsHeroBanner &&
      _vm.blok.AboutUsHeroBanner.filename &&
      !_vm.blok.AboutUsHeroVideo.filename
    )?_c('div',{staticClass:"about-us-hero-image"},[_c('div',[_c('section',{staticClass:"about-hero bg-cover bg-no-repeat",style:({
          background:
            'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' +
            _vm.blok.AboutUsHeroBanner.filename + '/m/fit-in/1900x530/' +
            ')',
        })},[_c('div',{staticClass:"about-text"},[_c('div',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.$storyapi.richTextResolver.render(_vm.blok.AboutUsHeroText))}})])])])]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }