<template>
  <!-- sivi slider -->
  <div>
    <a
      :href="blok.link"
      target="_blank"
      draggable="false"
      aria-label="Brand link from slider"
    >
      <nuxt-img
        quality="90"
        format="webp"
        placeholder
        :src="blok.image.filename"
        draggable="false"
        alt="slider"
      />
    </a>
  </div>
</template>
<script>
export default {
  name: "BrandsSliderItem",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
};
</script>
