<template>
  <div class="py-4 relative">
    <div
      v-html="$storyapi.richTextResolver.render(blok.title)"
      class="text-red-title text-3xl mb-8 font-mont md:min-h-[4.6rem] xl:min-h-[2rem]"
    />
    <div
      v-html="$storyapi.richTextResolver.render(blok.text)"
      class="richtext font-fgb text-lg"
    />
    <div v-if="blok.link">
      <div v-if="blok.link.url != ''">
        <a :href="blok.link.url" aria-label="Arrow right">
          <Icon
            class="icon"
            icon="charm:chevron-right"
            width="50"
            height="50"
          />
        </a>
      </div>
      <div v-else>
        <nuxt-link
          v-if="blok.link.cached_url"
          :to="localePath('/' + blok.link.cached_url)"
        >
          <Icon
            class="icon"
            icon="charm:chevron-right"
            width="50"
            height="50"
          />
        </nuxt-link>
      </div>
    </div>
  </div>
</template>
<script>
import { Icon } from "@iconify/vue2";

export default {
  name: "AboutUsCards",
  components: {
    Icon,
  },
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
};
</script>
<style lang="scss" scoped>
.icon {
  color: #cd1619;
  position: absolute;
  bottom: 0;
  right: 0;
}
::v-deep .richtext {
  p {
    @apply pr-8;
    @media only screen and (max-width: 767px) {
      @apply pr-0;
    }
  }
}
</style>
