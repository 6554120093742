<template>
  <div class="brands-slider-wrapper">
    <div class="brands-slider-tiny">
      <client-only>
        <vue-tiny-slider v-bind="tinysettings">
          <div v-for="item in blok.blocks" :key="item.id" class="brands-list">
            <a :href="item.link" draggable="false" aria-label="Brand link">
              <nuxt-img
                quality="90"
                format="webp"
                placeholder
                :src="item.image.filename"
                draggable="false"
                alt="brand image"
                width="96"
                height="96"
              />
            </a>
          </div>
        </vue-tiny-slider>
      </client-only>
    </div>
  </div>
</template>
<script>
export default {
  name: "BrandsSlider",

  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
  setup(props) {
    const tinysettings = {
      container: "#mouse-drag",
      mouseDrag: true,
      autoplay: true,
      autoplayTimeout: 4000,
      autoplayButton: false,
      autoplayButtonOutput: false,
      loop: true,
      controls: false,
      nav: false,
      "nav-container": false,
      items: 2,
      mouseDrag: true,
      slideBy: 1,
      swipeAngle: false,
      speed: 400,
      edgePadding: 0,
      gutter: 0,
      responsive: {
        640: {
          items: 3,
        },
        768: {
          items: 4,
        },
        900: {
          items: 5,
        },
        1024: {
          items:
            props.blok.blocks.length === 9 ? 7 : props.blok.blocks.length - 1,
        },
      },
    };
    return {
      tinysettings,
    };
  },
};
</script>
<style scoped lang="scss">
.brands-slider-wrapper {
  min-height: 96px;
  @include for-mobile {
    height: auto;
    padding: 0;
  }
}
.brands-slider-tiny {
  @apply bg-grey flex items-center;
  .brands-list {
    height: 6rem;
    align-items: center;
    display: inline-flex;
    justify-content: center;
    a {
      height: 6rem;
      img {
        display: block;
        height: 100%;
        width: 100%;
        object-fit: contain;
        max-width: 140px;
      }
    }
    @media only screen and (max-width: 769px) {
      height: 3rem;
    }
  }
}
</style>
