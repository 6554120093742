<template>
  <div>
    <div>
      <div class="ups py-2 bg-grey">
        <VueSlickCarousel v-bind="settings" class="slick-slider container">
          <div v-for="item in ups" :key="item.index">
            <div class="flex justify-center px-2">
              <nuxt-img
                :src="`/icons/icons/${item.img}`"
                alt="box icon"
                class="w-8 h-auto mr-2"
                loading="lazy"
                width="32"
                height="20"
              />
              <span class="text-brownish-grey text-xs font-fgb">{{
                $t(item.text)
              }}</span>
            </div>
          </div>
        </VueSlickCarousel>
      </div>
    </div>
  </div>
</template>
<script>
import VueSlickCarousel from "vue-slick-carousel";
import { ref } from "@nuxtjs/composition-api";

export default {
  name: "Ups",
  components: {
    VueSlickCarousel,
  },
  setup() {
    const settings = ref({
      arrows: false,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
      variableWidth: true,
      draggable: false,
      responsive: [
        {
          breakpoint: 1840,
          settings: {
            draggable: true,
            slidesToShow: 2,
            autoplay: true,
          },
        },
        {
          breakpoint: 1366,
          settings: {
            draggable: true,
            slidesToShow: 1,
            autoplay: true,
          },
        },
      ],
    });
    const ups = [
      {
        img: "USP_Icons-Extensive-Inventory.webp",
        text: "Extensive Inventory",
      },
      {
        img: "USP_Icons-Swift-Global-Delivery.webp",
        text: `Swift Global Delivery`,
      },
      {
        img: "USP_Icons-European-excellence.webp",
        text: "European excellence in products and development",
      },
    ];

    return {
      settings,
      ups,
    };
  },
};
</script>
<style lang="scss" scoped>
.desktop {
  @include for-mobile {
    display: none;
  }
}
.ups ::v-deep .slick-slider {
  display: block;
  overflow: hidden;
  .slick-list {
    .slick-track {
      display: flex;
      justify-content: space-around;
      align-items: center;
      overflow: hidden;
      .slick-slide {
        width: 100%;
        text-align: center;
        margin: auto;
        div div {
          display: flex !important;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
</style>
