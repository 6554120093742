<template>
  <div class="container relative">
    <nuxt-link v-if="blok.go_back_overview" :to="localePath('/inspiration')">
      <div class="flex items-center">
        <Icon
          class="icon mr-2"
          icon="charm:chevron-left"
          width="50"
          height="50"
        />
        <div
          v-html="$storyapi.richTextResolver.render(blok.go_back_overview)"
          class="ml-6 font-fgb"
        ></div>
      </div>
    </nuxt-link>
    <h2 class="title text-center font-mont">{{ blok.title }}</h2>
    <div class="md:flex gap-x-20 md:my-20 my-6">
      <div class="left-block flex-1">
        <div
          v-html="$storyapi.richTextResolver.render(blok.blocks_left)"
          class="richtext font-fgb"
        />
      </div>
      <div class="right-block flex-1">
        <div
          v-html="$storyapi.richTextResolver.render(blok.blocks_right)"
          class="richtext font-fgb"
        />
      </div>
    </div>
    <div class="discover-all relative">
      <nuxt-link v-if="blok.discover_all" :to="localePath('/inspiration')">
        <div
          v-html="$storyapi.richTextResolver.render(blok.discover_all)"
          class="discover-all-text text-center font-mont"
        />
        <Icon
          class="icon icon-right mr-2"
          icon="charm:chevron-right"
          width="50"
          height="50"
        />
      </nuxt-link>
    </div>
  </div>
</template>
<script>
import BlogItem from "./inspirationPage/BlogItem.vue";
import { Icon } from "@iconify/vue2";

export default {
  name: "ArticleDetails",
  props: {
    blok: {
      type: Object,
      required: false,
      default: {},
    },
  },
  components: {
    BlogItem,
    Icon,
  },
};
</script>
<style lang="scss" scoped>
.container {
  margin: 10rem auto 3rem auto;
  @media only screen and (max-width: 1025px) {
    margin: 4rem auto 3rem auto;
  }
  @media only screen and (max-width: 481px) {
    margin: 2rem auto 2rem auto;
  }
}
.icon {
  border-radius: 50%;
  border: 1px solid #cd1619;
  color: #cd1619;
}
.title {
  font: normal normal bold 55px/55px Montserrat;
  margin-top: 50px;
  @media only screen and (max-width: 481px) {
    margin-top: 20px;
  }
}
::v-deep .richtext {
  p {
    margin: 30px 0;
    font-size: 18px;
    img {
      margin: 60px 0;
      max-height: 320px;
      object-fit: cover;
      border-radius: 0.75rem;
    }
  }
}
.discover-all {
  max-width: 530px;
  margin: auto;
  font: normal normal normal 35px/55px Montserrat;
  color: #cd1619;
  &-text {
    line-height: 41px;
  }
  @media only screen and (max-width: 769px) {
    max-width: 500px;
  }
  @media only screen and (max-width: 481px) {
    /* max-width: 250px; */
    .discover-all-text {
      max-width: 250px;
      margin: auto;
    }
    .icon-right {
      right: 0;
    }
    font: normal normal normal 30px/32px Montserrat;
  }
  @media only screen and (max-width: 420px) {
    .discover-all-text {
      margin: 0;
    }
    font: normal normal normal 24px/32px Montserrat;
  }
}
.icon-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -80px;
  @media only screen and (max-width: 481px) {
    right: -50px;
  }
}
</style>
