<template>
  <div
    v-if="
      isMobile &&
      blok.about_us_hero_mobile_img &&
      blok.about_us_hero_mobile_img.filename
    "
    class="about-us-hero-banner-mobile mobile-only"
  >
    <div class="about-us-hero-image-mobile">
      <div>
        <section class="about-hero-mobile">
          <img
            :src="blok.about_us_hero_mobile_img.filename + '/m/filters:format(webp)'"
            alt="blok.about_us_hero_mobile_img.alt"
          />
        </section>
      </div>
    </div>
  </div>
  <div v-else>
    <div
      class="about-us-hero-banner desktop-only"
      v-if="blok.AboutUsHeroVideo && blok.AboutUsHeroVideo.filename"
      :style="{ 'background-color': blok.AboutUsHeroBackgroundColor }"
    >
      <div class="about-wrapper">
        <video class="m-auto" autoplay loop muted>
          <source :src="blok.AboutUsHeroVideo.filename" type="video/mp4" />
        </video>
      </div>
      <div class="about-text">
        <div
          class="text"
          v-html="$storyapi.richTextResolver.render(blok.AboutUsHeroText)"
        ></div>
      </div>
    </div>
    <div
      class="about-us-hero-image"
      v-else-if="
        blok.AboutUsHeroBanner &&
        blok.AboutUsHeroBanner.filename &&
        !blok.AboutUsHeroVideo.filename
      "
    >
      <div>
        <section
          class="about-hero bg-cover bg-no-repeat"
          :style="{
            background:
              'linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url(' +
              blok.AboutUsHeroBanner.filename + '/m/fit-in/1900x530/' +
              ')',
          }"
        >
          <div class="about-text">
            <div
              class="text"
              v-html="$storyapi.richTextResolver.render(blok.AboutUsHeroText)"
            ></div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@nuxtjs/composition-api";
export default {
  name: "AboutUsHeroBanner",
  props: {
    blok: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup() {
    let isMobile = ref(false);
    if (process.client) {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 768) {
        isMobile.value = true;
      }
    }
    return {
      isMobile,
    };
  },
};
</script>
<style lang="scss" scoped>
video::-webkit-media-controls-panel {
  display: none !important;
  opacity: 1 !important;
}
.about-text {
  @apply flex justify-center items-center font-mont relative;
  width: 100%;
  height: calc(80vh - 243px);
  background-position: center !important;
}
.about-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.about-wrapper video {
  width: 100%;
  height: 100%;
}
.about-us-hero-banner {
  position: relative;
  width: 100%;
  height: calc(80vh - 243px);
  // padding-bottom: 35.25%; /* 16:9 aspect ratio (9 / 16 * 100) */

  ::v-deep .text {
    text-align: center;
    font: normal normal normal 55px/80px Montserrat;
    color: white;
    b {
      font: normal normal bold 95px/100px Montserrat;
    }
    p:last-child {
      font: normal normal normal 45px/100px Montserrat;
    }
  }

  @media only screen and (max-width: 1024px) {
    ::v-deep .text {
      text-align: center;
      font: normal normal normal 45px/55px Montserrat;
      color: white;
      b {
        font: normal normal bold 70px/75px Montserrat;
      }
      p:last-child {
        font: normal normal normal 35px/55px Montserrat;
      }
    }
  }
  @media only screen and (max-width: 768px) {
    height: 50vh;
    .about-text {
      height: 50vh;
    }
    ::v-deep .text {
      text-align: center;
      font: normal normal normal 44px/55px Montserrat;
      color: white;
      b {
        font: normal normal bold 55px/55px Montserrat;
      }
      p:last-child {
        font: normal normal normal 35px/55px Montserrat;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    height: calc(100vh - 60vh);
    .about-text {
      height: calc(100vh - 60vh);
    }
    ::v-deep .text {
      @apply px-2;
      text-align: center;
      font: normal normal normal 33px/44px "Adobe Caslon Pro", serif;
      color: white;
      b {
        font: normal normal bold 33px/44px "Adobe Caslon Pro", serif;
      }
      p:last-child {
        font: normal normal normal 28px/44px "Adobe Caslon Pro", serif;
      }
    }
  }
}
.about-us-hero-image {
  @apply relative;
  .about-hero {
    @apply flex justify-center items-center font-mont;
    width: 100%;
    height: calc(80vh - 243px);
    max-height: 530px;
    background: #3c3c3c;
    background-position: center !important;
    ::v-deep .text {
      text-align: center;
      font: normal normal normal 55px/80px "Adobe Caslon Pro", serif;
      color: white;
      b {
        font: normal normal bold 95px/100px "Adobe Caslon Pro", serif;
      }
      p:last-child {
        font: normal normal normal 45px/100px "Adobe Caslon Pro", serif;
      }
    }

    @media only screen and (max-width: 1024px) {
      ::v-deep .text {
        text-align: center;
        font: normal normal normal 45px/55px "Adobe Caslon Pro", serif;
        color: white;
        b {
          font: normal normal bold 70px/75px "Adobe Caslon Pro", serif;
        }
        p:last-child {
          font: normal normal normal 35px/55px "Adobe Caslon Pro", serif;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      height: calc(80vh - 30vh);
      ::v-deep .text {
        text-align: center;
        font: normal normal normal 44px/55px "Adobe Caslon Pro", serif;
        color: white;
        b {
          font: normal normal bold 55px/55px "Adobe Caslon Pro", serif;
        }
        p:last-child {
          font: normal normal normal 35px/55px "Adobe Caslon Pro", serif;
        }
      }
    }
    @media only screen and (max-width: 480px) {
      height: calc(100vh - 60vh);
      .about-text {
        height: calc(100vh - 60vh);
      }
      ::v-deep .text {
        @apply px-2;
        text-align: center;
        font: normal normal normal 33px/44px "Adobe Caslon Pro", serif;
        color: white;
        b {
          font: normal normal bold 33px/44px "Adobe Caslon Pro", serif;
        }
        p:last-child {
          font: normal normal normal 28px/44px "Adobe Caslon Pro", serif;
        }
      }
    }
  }
}
</style>
